:root {
    --notification-height: 60vh;
}

.notification-wrapper-content-area,
.notification-wrapper-content-area .notification-infinite-scroll-area {
    min-height: var(--notification-height);
    max-height: var(--notification-height);
}

.notification-wrapper-content-area .notification-empty-text {
    max-width: 200px;
}

@media (max-width: 768px) {
    :root {
        --notification-height: 91vh;
    }
}
